import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ArrowSlideAnimation = () => {
  return (
    <div className="relative w-6 h-6 overflow-clip">
      <div className="absolute flex flex-row transition-all duration-500 -left-6 group-hover:left-0">
        <FontAwesomeIcon className="w-6 h-6" icon={faAngleRight} />
        <FontAwesomeIcon className="w-6 h-6" icon={faAngleRight} />
      </div>
    </div>
  )
}

export default ArrowSlideAnimation
