type Props = {
  children: React.ReactNode
  className?: string
  nopadding?: boolean
  position?: string
}

const SpacedRow = ({
  children,
  className,
  nopadding = false,
  position,
}: Props) => {
  return (
    <div
      className={`grid grid-cols-1 lg:grid-cols-2 ${
        position ?? 'items-center'
      } ${!nopadding && 'py-8 md:py-10 lg:py-14'} ${className ?? ''}`}
    >
      {children}
    </div>
  )
}

export default SpacedRow
