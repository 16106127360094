import classNames from 'classnames'

type Props = {
  children: React.ReactNode
  above?: boolean
  className?: string
}

const RightCol = ({ children, above, className = '' }: Props) => {
  const clsnm = classNames(`lg:pl-30 lg:pr-30 ${className}`, {
    'pb-12 lg:pb-0': above,
    'row-start-1': above ?? false,
  })

  return <div className={clsnm}>{children}</div>
}

export default RightCol
