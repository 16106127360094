import classNames from 'classnames'

type Props = {
  title: string
  subtitle?: string
  extraClasses?: string
  dividerColor?: 'black' | 'blue'
  subtitleOnTop?: boolean
}

const getDividerClass = (dividerColor) => {
  return classNames({
    'bg-black': dividerColor === 'black',
    'bg-blue': dividerColor === 'blue',
  })
}

const SeparatorHeader = ({
  title,
  subtitle,
  extraClasses,
  dividerColor,
  subtitleOnTop = false,
}: Props) => {
  const titleOverSubtitle = (
    <>
      <h1 className="text-4xl leading-normal text-center font-medium">
        {title}
      </h1>
      <h2 className="text-normal text-center my-4">{subtitle}</h2>
    </>
  )

  const titleUnderSubtitle = (
    <>
      <h2 className="text-normal text-center">{subtitle}</h2>
      <h1 className="text-4xl leading-normal text-center font-medium mt-2 mb-4">
        {title}
      </h1>
    </>
  )

  return (
    <div
      className={`max-w-outer-container mx-auto px-8 xl:px-container-padding ${
        extraClasses ?? ''
      }`}
    >
      {subtitleOnTop ? titleUnderSubtitle : titleOverSubtitle}
      <div
        className={`w-20 h-0.5 mx-auto ${
          dividerColor ? getDividerClass(dividerColor) : 'bg-blue'
        }`}
      ></div>
    </div>
  )
}

export default SeparatorHeader
