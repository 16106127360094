type Props = {
  children: React.ReactNode
  className?: string
}

const Container = ({ children, className = '' }: Props) => {
  return (
    <div
      className={`max-w-outer-container mx-auto px-4 md:px-8 xl:px-container-padding ${className}`}
    >
      {children}
    </div>
  )
}

export default Container
