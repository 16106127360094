import React, { useEffect, useRef, useState } from 'react'

import SwiperCore from 'swiper'
import {
  Scrollbar,
  Mousewheel,
  Autoplay,
  Navigation,
  FreeMode,
} from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { SwiperOptions } from 'swiper/types'

SwiperCore.use([Scrollbar, Mousewheel, Autoplay, FreeMode])

type Props = {
  children: React.ReactNode[]
  settings: SwiperOptions
  className?: string
  swiperClassName?: string
  wrapperClassName?: string
  sliderClassName?: string
}

export const tiledSettings: SwiperOptions = {
  spaceBetween: 20,
  slidesPerView: 1,
  navigation: true,
  modules: [Navigation],
  scrollbar: {
    draggable: true,
  },
  mousewheel: false,
  cssMode: true,
  grabCursor: true,
  autoplay: {
    delay: 1500,
    disableOnInteraction: true,
  },
  centerInsufficientSlides: true,
  breakpoints: {
    '@0.50': {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    '@0.75': {
      slidesPerView: 3,
      spaceBetween: 40,
      cssMode: false,
      autoplay: {
        delay: 3000,
      },
    },
    '@1.50': {
      slidesPerView: 4,
      spaceBetween: 40,
      cssMode: false,
      autoplay: {
        delay: 3000,
      },
    },
  },
}

export const singleImageSettings: SwiperOptions = {
  slidesPerView: 1,
  spaceBetween: 20,
  scrollbar: {
    draggable: true,
  },
  mousewheel: false,
  autoplay: {
    delay: 1500,
    disableOnInteraction: true,
  },
}

export const gridSettings: SwiperOptions = {
  slidesPerView: 'auto' as const,
  spaceBetween: 10,
  mousewheel: false,
  autoplay: {
    delay: 3000,
    disableOnInteraction: true,
  },
  scrollbar: {
    draggable: true,
  },
  navigation: true,
  modules: [Navigation],
}

export const autoSlidesSettings: SwiperOptions = {
  slidesPerView: 1,
  centeredSlides: true,
  spaceBetween: 10,
  scrollbar: {
    draggable: true,
  },
  mousewheel: false,
  autoHeight: true,
}

export const partialTiledSettings: SwiperOptions = {
  slidesPerView: 1.2,
  scrollbar: {
    draggable: true,
  },
  mousewheel: false,
  spaceBetween: 10,
  slidesOffsetAfter: 32,
  slidesOffsetBefore: 32,
  grabCursor: true,
  breakpoints: {
    '768': {
      slidesPerView: 2.2,
    },
    '1024': {
      slidesPerView: 3,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0,
      spaceBetween: 20,
    },
  },
}

export const infiniteLoopSettings: SwiperOptions = {
  autoplay: {
    delay: 0,
  },
  loop: true,
  modules: [FreeMode, Autoplay],
  slidesPerView: 'auto',
  spaceBetween: 8,
  speed: 3500,
}

const SwiperCarousel = ({
  children,
  settings,
  className,
  swiperClassName = 'pb-[50px]', // add space between the bottom of the elements
                                // and the bottom of the container so that the
                                // scrollbar is not flush against the elements
  wrapperClassName,
  sliderClassName,
}: Props) => {
  // this ref will keep track of the containing div element, so that autoplay can be enabled whenever this scrolls into view
  const swiperRef = useRef<HTMLDivElement>()

  const [swiper, setSwiper] = useState<SwiperCore | undefined>(undefined)

  useEffect(() => {
    if (!settings.autoplay || settings.loop) {
      return
    }

    const observer = new IntersectionObserver((entries, observer) => {
      if (swiper && entries[0].isIntersecting) {
        // stop the observer that we created, and set the element to be "in to view". This will not pause autoplay when the user scrolls out of view, as the observer will be disconnected by then
        observer.disconnect()
        swiper.autoplay.start()
      }
    })

    observer.observe(swiperRef.current)

    return () => {
      observer.disconnect()
    }
  }, [swiper])

  // this effect is called when swiper loads and sets the swiper-core instance
  // except when the loop is enabled, in which case autoplay is started on load
  useEffect(() => {
    if (swiper && !settings.loop) {
      // pause autoplay on load. it is started when the swiper component scrolls into view
      swiper.autoplay.stop()
    }
  }, [swiper])

  return (
    <div ref={swiperRef} className={className}>
      <Swiper
        {...settings}
        onSwiper={setSwiper}
        wrapperClass={wrapperClassName}
        className={swiperClassName}
      >
        {children.map((c, i) => (
          <SwiperSlide key={i} className={sliderClassName}>
            {c}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default SwiperCarousel
