import { twMerge } from 'tailwind-merge'

interface ZoomAnimationProps {
  children: React.ReactNode
  wrapperClassName?: string
}

const ZoomAnimation = ({ children, wrapperClassName }: ZoomAnimationProps) => {
  const wrapperCls = twMerge(
    'w-full h-full overflow-hidden mb-8 md:mb-12',
    wrapperClassName,
  )
  return (
    <div className={wrapperCls}>
      <div className="w-full h-full transition-all ease-in-out duration-500 group-hover:filter group-hover:transform group-hover:scale-110">
        {children}
      </div>
    </div>
  )
}

export default ZoomAnimation
