import Link from 'next/link'

type Props = {
  href: string
  title: string
  className?: string
}

const UnderlinedLink = ({ href, title, className }: Props) => {
  return (
    <div className="inline-block pb-5 group relative">
      <Link href={href} className={`font-medium ${className ?? ''}`}>
        {title}
      </Link>
      {/* Border-bottom effect for the transition animation */}
      <span className="absolute -bottom-1 h-0.5 right-0 w-full bg-blue transition-all group-hover:w-0 duration-300"></span>
      <span className="absolute -bottom-1 h-0.5 left-0 w-0 bg-blue transition-all delay-150 group-hover:w-full duration-300"></span>
    </div>
  )
}

export default UnderlinedLink
