import classNames from 'classnames'

type Props = {
  children: React.ReactNode
  below?: boolean
  className?: string
}

const LeftCol = ({ children, below, className = '' }: Props) => {
  const clsnm = classNames(`lg:pr-30 lg:pl-30 ${className}`, {
    'pb-12 lg:pb-0': !below,
    'row-start-2 lg:row-start-1': below ?? false,
  })

  return <div className={clsnm}>{children}</div>
}

export default LeftCol
