type CaseTagProps = {
  label: string
}

const CaseTag = ({ label }: CaseTagProps) => {
  return (
    <div className="p-2 mr-6 mt-6 text-sm bg-black rounded-md bg-opacity-40">
      {label}
    </div>
  )
}

export default CaseTag
